import { useEffect, useRef } from "react";
import { capitalize } from "utils";
import * as fabric from "fabric";
import cn from "classnames";

const FILTER_OPTIONS = [
  "original",
  "studio",
  "spotlight",
  "prime",
  "classic",
  "edge",
  "lurninate",
];

interface FilterControlProps {
  image: any;
  applyFilter: any;
  selectedFilter: string;
}

interface ControlItemProps {
  option: string;
  applyFilter: any;
  image: any;
  selectedFilter: string;
}

const ControlItem: React.FC<ControlItemProps> = ({
  option,
  applyFilter,
  image,
  selectedFilter,
}) => {
  const canvasRef = useRef<any>(null);
  const previewRef = useRef<any>(null);
  const fabricCanvasRef = useRef<any>(null);

  useEffect(() => {
    if (!option || !image) return;
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      width: previewRef?.current?.offsetWidth - 4,
      height: previewRef?.current?.offsetWidth - 4,
    });

    fabric.FabricImage.fromURL(image!).then((img) => {
      const canvasWidth = previewRef?.current?.offsetWidth - 4;
      const canvasHeight = previewRef?.current?.offsetWidth - 4;

      // Get the aspect ratio of the image
      const imageAspectRatio = img.width / img.height;

      // Get the aspect ratio of the canvas
      const canvasAspectRatio = canvasWidth / canvasHeight;

      let scaleFactor;

      if (imageAspectRatio > canvasAspectRatio) {
        scaleFactor = canvasHeight / img.height;
      } else {
        scaleFactor = canvasWidth / img.width;
      }

      img.scale(scaleFactor);

      img.set({
        left: (canvasWidth - img.width * scaleFactor) / 2,
        top: (canvasHeight - img.height * scaleFactor) / 2,
        originX: "left",
        originY: "top",
      });
      img.selectable = false;

      switch (option) {
        case "original":
          img.filters = [];
          break;

        case "studio":
          img.filters.push(new fabric.filters.Grayscale());
          img.filters.push(
            new fabric.filters.Contrast({
              contrast: 0.2,
            })
          );
          break;

        case "spotlight":
          img.filters.push(new fabric.filters.Brightness({ brightness: 0.2 }));
          img.filters.push(
            new fabric.filters.Contrast({
              contrast: 0.3,
            })
          );
          break;

        case "prime":
          img.filters.push(new fabric.filters.Sepia());
          break;

        case "classic":
          img.filters.push(new fabric.filters.Brightness({ brightness: -0.1 }));
          img.filters.push(
            new fabric.filters.Contrast({
              contrast: 0.4,
            })
          );
          break;

        case "edge":
          img.filters.push(new fabric.filters.Invert());
          img.filters.push(
            new fabric.filters.Contrast({
              contrast: 0.6,
            })
          );
          break;

        case "lurninate":
          img.filters.push(new fabric.filters.Saturation({ saturation: 0.5 }));
          img.filters.push(
            new fabric.filters.Brightness({
              brightness: 0.3,
            })
          );
          break;
      }
      img.applyFilters();
      fabricCanvas.clear();
      fabricCanvas.add(img);
      fabricCanvas.renderAll();
      fabricCanvasRef.current = fabricCanvas;
    });

    return () => {
      if (fabricCanvasRef?.current) {
        fabricCanvasRef?.current?.dispose();
        fabricCanvasRef.current = null;
      }
    };
  }, [option, image]);

  return (
    <div
      className="col-span-1 cursor-pointer"
      onClick={() => {
        applyFilter(option);
      }}
      ref={previewRef}
    >
      <div
        className={cn(
          "w-full aspect-[1/1] rounded-full flex justify-center items-center",
          {
            "border-2 border-jll-icon-rag-success-2": selectedFilter === option,
          }
        )}
      >
        <canvas ref={canvasRef} className="w-full aspect-[1/1] rounded-full" />
      </div>

      <p className="text-center mt-3">
        {capitalize(option)} - {selectedFilter}
      </p>
    </div>
  );
};

const FilterControl: React.FC<FilterControlProps> = ({
  image,
  applyFilter,
  selectedFilter,
}) => {
  return (
    <div className="grid grid-cols-4 gap-10">
      {FILTER_OPTIONS.map((option, idx) => (
        <ControlItem
          key={idx}
          option={option}
          applyFilter={applyFilter}
          image={image}
          selectedFilter={selectedFilter}
        />
      ))}
    </div>
  );
};

export default FilterControl;
