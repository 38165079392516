import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import JLLLogo from "assets/images/jll_logo.svg";
import { SolAvatar, SolButton, SolIcon } from "@solstice/sol-react";
import { capitalize, capitalizeName } from "utils";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { GlobalContext } from "context";
import {
  SET_ADD_PROFILE_MODAL,
  SET_ADD_TAG_MODAL,
  SET_ALERT_MODAL,
  SET_LOADING_MODAL,
  URLS,
} from "constant";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { useApiCall } from "hooks";
import { getUserAPI, logoutApi, switchUserAPI } from "services";
import { SelectAutoComplete, Toast } from "ui-atoms";
import { BrokerProps, ManageUserProps } from "types";

interface TopBarProps {
  broker?: BrokerProps | null;
}

const TopBar: React.FC<TopBarProps> = ({broker}) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const mainSession = JSON.parse(
    sessionStorage.getItem("mainSession") || "null"
  );
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [logout] = useApiCall(logoutApi);
  const [switchUser] = useApiCall(switchUserAPI);
  const [searchParams] = useSearchParams();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [getUsers] = useApiCall(getUserAPI);
  const isProfile =
    location?.pathname?.replaceAll("/", "")?.toLowerCase() === "profile";
  const isMetadata = location?.pathname?.includes("metadata");
  const isLocation =
    location?.pathname?.replaceAll("/", "")?.toLowerCase() === "location";
  const isUser = location?.pathname?.includes("user");
  let subLabel = "";
  let tagLabel = "";

  useEffect(() => {
    if (!session || session?.id === currentUser?.value) return;
    setCurrentUser({
      label: session?.get_full_name || session?.email,
      value: session?.id,
    });
  }, [session]);

  if (location?.pathname?.includes("profile")) {
    subLabel = broker ? [broker?.first_name, broker?.last_name]?.join(" "):"List";
    tagLabel = isProfile ? "Profile" : "";
  } else if (isMetadata) {
    subLabel = "Metadata management";
    switch (searchParams?.get("tab")) {
      case "service":
      case "country":
      case "industry":
      case "state":
      case "city":
      case "market":
        tagLabel = capitalize(searchParams?.get("tab") || "");
        break;
      default:
        tagLabel = "Industry";
    }
  } else if (location?.pathname?.includes("location")) {
    subLabel = "Office location management";
    tagLabel = isLocation ? "Location" : "";
  } else if (isUser) {
    subLabel = "User Management";
    tagLabel = "";
  }

  const onClickLogout = async () => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        title: "Logging out...",
      },
    });
    logout()
      .then((data: any) => {
        window.location.href = data?.redirect;
      })
      .finally(() => {
        sessionStorage.clear();
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: "",
          },
        });
      });
  };

  const loadCoordinatorOptions = async (keyword: string) => {
    return await getUsers({ keyword }).then((res: any) => {
      return res.docs.map((user: ManageUserProps) => ({
        value: user.pk,
        label:
          user?.first_name || user?.last_name
            ? [user?.first_name, user?.last_name].join(" ")
            : user?.email,
      }));
    });
  };

  const handleUser = async (option: any) => {
    try {
      if (!option) {
        const action = async () => {
          const payload = {
            user: mainSession?.id,
          };
          await switchUser(payload);
          navigate("/");
          window.location.reload();
        };

        if (!mainSession) {
          setCurrentUser({
            label: session?.get_full_name || session?.email,
            value: session?.id,
          });
          navigate("/");
          window.location.reload();
          return;
        }

        dispatch({
          type: SET_ALERT_MODAL,
          payload: {
            open: true,
            kind: "error",
            title: `Switch User`,
            description: `Are you sure you want to "${
              mainSession?.get_full_name || mainSession?.email
            }"?`,
            icon: "warning",
            btn1: {
              label: "Cancel",
              onClick: () => {
                dispatch({
                  type: SET_ALERT_MODAL,
                  payload: {
                    open: false,
                    kind: "",
                    title: "",
                    description: "",
                    icon: "",
                    btn1: null,
                    btn2: null,
                  },
                });
              },
            },
            btn2: {
              label: "Confirm",
              onClick: () => {
                dispatch({
                  type: SET_ALERT_MODAL,
                  payload: {
                    open: false,
                    kind: "",
                    title: "",
                    description: "",
                    icon: "",
                    btn1: null,
                    btn2: null,
                  },
                });
                action();
              },
            },
          },
        });
        return;
      }
      if (option?.value === session?.id) return;
      const action = async () => {
        const payload = {
          user: option?.value,
        };
        await switchUser(payload);
        window.location.reload();
      };

      dispatch({
        type: SET_ALERT_MODAL,
        payload: {
          open: true,
          kind: "error",
          title: `Switch User`,
          description: `Are you sure you want to "${option?.label}"?`,
          icon: "warning",
          btn1: {
            label: "Cancel",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                },
              });
            },
          },
          btn2: {
            label: "Confirm",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                },
              });
              action();
            },
          },
        },
      });
    } catch (err) {}
  };

  return (
    <div
      className="w-full h-[80px] flex items-center px-10 justify-between bg-jll-surface-base-default cursor-pointer"
      data-theme="dark"
    >
      <Link to="/" className="flex flex-row items-center space-x-3">
        <LazyLoadImage src={JLLLogo} className="h-full" />
        <span className="text-white">
          Profile Management {!!subLabel?.length && ` | ${subLabel}`}
        </span>
      </Link>

      {/* Header */}
      <div className="h-full flex flex-row items-center">
        <div className="flex flex-row items-center space-x-6">
          {!!tagLabel?.length && (
            <SolButton
              onClick={() => {
                if (isProfile)
                  dispatch({
                    type: SET_ADD_PROFILE_MODAL,
                    payload: true,
                  });
                else if (isMetadata)
                  dispatch({
                    type: SET_ADD_TAG_MODAL,
                    payload: true,
                  });
                else if (isLocation) navigate(`${URLS.LOCATION.ROOT}/new`);
              }}
              variant="secondary"
            >
              {`Add ${tagLabel}`}
              <SolIcon icon="add" />
            </SolButton>
          )}
          <div className="">
            {/* <SolAvatar
              name={capitalizeName(session?.get_full_name)}
              status="online"
              size="24"
            />
            <span className="text-sm text-white">
              {session?.get_full_name || session?.email}
            </span> */}
            <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <MenuButton className="flex flex-row items-center space-x-3">
                    <SolAvatar
                      name={capitalizeName(session?.get_full_name)}
                      status="online"
                      size="24"
                    />
                    <span className="text-sm text-white">
                      {session?.get_full_name || session?.email}
                    </span>
                  </MenuButton>
                  {open && (
                    <MenuItems
                      anchor="bottom end"
                      transition
                      className="mt-2 w-56 origin-top-right z-10 rounded-md border border-white/5 bg-white shadow-xl p-1 text-sm/6  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                    >
                      {(session?.is_superuser ||
                        mainSession?.is_superuser ||
                        session?.is_staff ||
                        mainSession?.is_staff ||
                        session?.permissions?.groups?.includes(
                          "Global Admin"
                        ) ||
                        mainSession?.permissions?.groups?.includes(
                          "Global Admin"
                        )) && (
                        <>
                          <MenuItem disabled as={Fragment}>
                            <div className="px-2 py-1 text-sm text-gray-700">
                              <SelectAutoComplete
                                label="Switch User"
                                name="user"
                                className="z-20 !mb-0"
                                onChange={(option) => {
                                  handleUser(option);
                                }}
                                value={currentUser}
                                loadOptions={loadCoordinatorOptions}
                                isMenuPortalTarget={false}
                                size="sm"
                              />
                            </div>
                          </MenuItem>
                          <hr className="my-1" />
                        </>
                      )}
                      <MenuItem>
                        <button
                          onClick={onClickLogout}
                          className="group flex w-full items-center rounded py-1 px-3 hover:bg-jll-stroke-interaction hover:bg-opacity-10"
                        >
                          Sign out
                        </button>
                      </MenuItem>
                    </MenuItems>
                  )}
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
