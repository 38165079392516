import { LazyLoadImage } from "react-lazy-load-image-component";
import { SolBadge, SolButton } from "@solstice/sol-react";
import LinkedinIcon from "assets/icons/linkedin.svg";
import { BrokerProps, LicenseProps, ProfileProps } from "types";
import { LoadingPage } from "ui-molecules";
import { Collapsable } from "ui-atoms";
import { parseOriginalJSON } from "utils";
import { useEffect, useRef, useState } from "react";
import { STATUS_ACTIVE } from "constant";

interface PreviewProps {
  profile?: ProfileProps | null;
  broker?: BrokerProps | null;
  isLoading?: boolean;
}

const TAGS: { label: string; key: keyof ProfileProps }[] = [
  { label: "Services", key: "services" },
  { label: "Industry", key: "industries" },
  { label: "Property Type", key: "property_type" },
];

const Preview: React.FC<PreviewProps> = ({ profile, broker, isLoading }) => {
  const [bgHeight, setBgHeight] = useState(320);
  const ref = useRef<any>(null);

  useEffect(() => {
    if (!ref?.current) return;
    setTimeout(() => {
      setBgHeight(
        ref?.current?.clientHeight ? ref?.current?.clientHeight + 48 : 320
      );
    }, 100);
  }, [ref?.current]);

  return (
    <>
      {isLoading ? (
        <LoadingPage rows={12} />
      ) : (
        <>
          <div className="flex flex-col w-full px-10 pt-12 relative">
            <div
              className="bg-jll-surface-base-secondary-subdued absolute top-0 left-0 right-0 z-0"
              style={{ height: `${bgHeight}px` }}
            />
            <div className="grid grid-cols-12 mb-9 z-10">
              <LazyLoadImage
                src={broker?.photos?.[0] || undefined}
                className="rounded-md col-span-3"
              />
              <div />
              <div className="col-span-8">
                <div className="pb-[56px]" ref={ref}>
                  <div className="mb-6 flex flex-row justify-between">
                    <div>
                      <h3 className="text-[32px] mb-6">
                        {[broker?.first_name, broker?.last_name].join(" ")}
                      </h3>
                      <p className="text-xl text-jll-text-base-subdued">
                        {broker?.job_title || ""}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-4">
                    {TAGS?.map(({ label, key }, idx) => {
                      if (!(profile as ProfileProps)?.[key]?.length) return;
                      return (
                        <div key={idx}>
                          <p className="mb-4">{label}</p>
                          <div className="flex flex-row items-center flex-wrap space-x-2">
                            {profile?.[key]?.map((item: any, idx1: number) => (
                              <SolBadge
                                key={idx1}
                                size="small"
                                variant="filled"
                              >
                                <span>{item?.name || item}</span>
                              </SolBadge>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* <div className="grid grid-cols-3 py-[72px] gap-10 border-b border-b-jll-stroke-default-1"> */}
                <div className="py-10 flex flex-col space-y-4">
                  {broker?.licenses?.map((license: LicenseProps, idx) => (
                    <Collapsable
                      label={license?.broker_of_record_name}
                      key={idx}
                    >
                      <div>
                        <div className="border-b border-b-jll-stroke-default-1 py-3">
                          <p>Individual License</p>
                          <div className="grid grid-cols-2 mt-2">
                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                License number
                              </p>
                              <p>{license?.individual_license_number}</p>
                            </div>
                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Type
                              </p>
                              <p>{license?.individual_license_type}</p>
                            </div>
                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Location
                              </p>
                              <p>{license?.individual_state_licensed}</p>
                            </div>
                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Phone
                              </p>
                              <p>{license?.individual_office_phone}</p>
                            </div>

                            <div className="col-span-2 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Address
                              </p>
                              <p>{license?.individual_office_address}</p>
                            </div>
                          </div>
                        </div>
                        <div className="py-3">
                          <p>Entity License</p>
                          <div className="grid grid-cols-2 mt-2">
                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                License number
                              </p>
                              <p>{license?.legal_entity_license}</p>
                            </div>
                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Name
                              </p>
                              <p>
                                {license?.legal_entity_name},{" "}
                                {license?.legal_entity_suffix}
                              </p>
                            </div>
                            {/* <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Suffix
                              </p>
                              <p>{license?.legal_entity_suffix}</p>
                            </div> */}
                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Phone
                              </p>
                              <p>{license?.main_office_phone}</p>
                            </div>

                            <div className="col-span-1 text-sm mb-3">
                              <p className="text-jll-text-base-subdued mb-1">
                                Address
                              </p>
                              <p>{license?.main_office_address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapsable>
                  ))}
                </div>

                <div className="text-jll-text-base-subdued">
                  <p className="text-xl mb-4 text-jll-text-base-default">Bio</p>
                  {profile?.bio && (
                    <div
                      className="bio-preview mb-12"
                      dangerouslySetInnerHTML={{
                        __html: parseOriginalJSON(profile?.bio),
                      }}
                    />
                  )}
                  {profile?.recognition && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Current responsibilities
                      </h4>
                      <p>{profile?.recognition}</p>
                    </div>
                  )}
                  {profile?.education && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Education
                      </h4>
                      <p>{profile?.education}</p>
                    </div>
                  )}
                  {profile?.affiliations && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Affiliations
                      </h4>
                      <p>{profile?.affiliations}</p>
                    </div>
                  )}

                  {profile?.experience && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Experience
                      </h4>
                      <p>{profile?.experience}</p>
                    </div>
                  )}

                  {!!profile?.awards?.filter(
                    (award) => award?.active_status === STATUS_ACTIVE
                  )?.length && (
                    <div>
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Awards
                      </h4>
                      {profile?.awards
                        ?.filter(
                          (award) => award?.active_status === STATUS_ACTIVE
                        )
                        ?.map((award, idx) => (
                          <div className="flex w-full mb-3" key={idx}>
                            <span className="w-full">{award?.award}</span>
                            <span className="w-full">
                              {award?.organization}
                            </span>
                            <span className="w-full">
                              {award?.years?.join(", ")}
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-t border-t-jll-light-stroke-default w-full mb-9" />
            <div className="grid grid-cols-12 mb-10">
              <div className="col-span-3">
                <p className="text-xl">Get in touch</p>
              </div>
              <div />
              <div className="col-span-7">
                <p className="mb-6">{`${[
                  profile?.cities?.[0]?.name,
                  profile?.cities?.[0]?.state,
                ]?.join(", ")}  |  ${profile?.country?.name}`}</p>
                <p className="mb-6">{broker?.telephone || ""}</p>
                <p className="mb-6">{broker?.email || ""}</p>
                {profile?.license?.length && (
                  <p className="mb-6">
                    License # IN - {profile?.license || ""}
                  </p>
                )}
                {!!broker?.linkedin?.length && (
                  <SolButton
                    variant="secondary"
                    onSol-click={() => {
                      window.open(broker?.linkedin, "_blank");
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <img src={LinkedinIcon} className="mr-2" />
                      Linkedin
                    </div>
                  </SolButton>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Preview;
