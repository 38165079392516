import { SolButton, SolIcon } from "@solstice/sol-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Slider } from "ui-atoms";
import cn from "classnames";

interface CropControlProps {
  image: any;
  setZoom: any;
  zoom?: number;
  setAspect: any;
  aspect: any;
  setRotation: any;
  rotation: number;
  flipX: boolean;
  setFlipX: any;
}

const CropControl: React.FC<CropControlProps> = ({
  image,
  zoom,
  setZoom,
  aspect,
  setAspect,
  setRotation,
  rotation,
  flipX,
  setFlipX,
}) => {
  return (
    <div>
      <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Rotate</p>
        <div className="flex flex-row items-center space-x-4">
          <SolButton
            variant="tertiary"
            onSol-click={() =>
              setRotation((prev: number) => {
                if (prev <= -270) return 0;
                return prev - 90;
              })
            }
          >
            <SolIcon icon="rotate_left" className="text-xl leading-none" />
          </SolButton>
          <SolButton
            variant="tertiary"
            onSol-click={() =>
              setRotation((prev: number) => {
                if (prev >= 270) return 0;
                return prev + 90;
              })
            }
          >
            <SolIcon icon="rotate_right" className="text-xl leading-none" />
          </SolButton>
          <SolButton
            variant="tertiary"
            onSol-click={() => {
              setFlipX((prev: boolean) => !prev);
            }}
          >
            <SolIcon icon="flip" className="text-xl leading-none" />
          </SolButton>
        </div>
      </div>
      <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Zoom</p>
        <div className="w-full px-2">
          <Slider
            min={1}
            step={0.01}
            max={3}
            value={zoom}
            onChange={(val: number) => setZoom(val)}
          />
        </div>
      </div>

      {/* <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Straighten</p>
        <div className="w-full px-2">
          <Slider min={0} max={100} />
        </div>
      </div> */}

      <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Image Ratio</p>
        <div className="flex flex-row space-x-10 mr-2">
          <div className="w-4/5 relative">
            <LazyLoadImage
              src={image}
              className="aspect-[4/5] w-full mb-3 rounded-lg object-cover cursor-pointer hover:shadow-gallery"
              onClick={() => setAspect(4 / 5)}
            />
            <p>Image ratio - 4:5</p>
            {aspect === 4 / 5 && (
              <div className="w-[26px] h-[26px] border-2 border-white rounded-full absolute left-2.5 top-3 bg-jll-icon-rag-success-2 flex justify-center items-center">
                <SolIcon
                  icon="check"
                  className="text-white font-bold"
                  size="20"
                />
              </div>
            )}
          </div>
          <div className="w-full relative">
            <LazyLoadImage
              src={image}
              className="aspect-[1/1] w-full mb-3 rounded-lg object-cover cursor-pointer hover:shadow-gallery"
              onClick={() => setAspect(1 / 1)}
            />
            <p>Image ratio - 1:1</p>
            {aspect === 1 / 1 && (
              <div className="w-[26px] h-[26px] border-2 border-white rounded-full absolute left-2.5 top-3 bg-jll-icon-rag-success-2 flex justify-center items-center">
                <SolIcon
                  icon="check"
                  className="text-white font-bold"
                  size="20"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropControl;
