import { SolButton, SolIcon } from "@solstice/sol-react";
import { SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { patchBrokerAPI, postImageAPI } from "services";
import { BrokerProps } from "types";

interface ProfilePhotoProps {
  broker: BrokerProps | null;
  setBroker: any;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ broker, setBroker }) => {
  const { dispatch } = useContext(GlobalContext);
  const { brokerId } = useParams();
  const navigate = useNavigate();
  const [patchBroker] = useApiCall(patchBrokerAPI);

  const handleAragon = () => {
    navigate(`/profile/${brokerId}/photo/tutorial`);
  };

  const handleSubmitFile = async (name: string, files: any) => {
    if (files?.length <= 0) return;
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        title: "Uploading files",
      },
    });
    try {
      let results: any[] = [];
      await Promise.all(
        files?.map(async (file: any) => {
          const result = await postImageAPI(file);
          // results.push({
          //   name: file?.name,
          //   url: result,
          //   size: Number(file?.size),
          //   create_timestamp: getUTCDate(),
          // });
          results.push(result);
        })
      );
      if (!!results?.length) {
        const payload: any = [...results];
        patchBroker({
          pk: broker?.pk || broker?.id,
          photos: payload,
        }).then((res: any) => {
          if (!res) return;
          setBroker({
            ...broker,
            photos: res?.photos,
          });
        });
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    }
  };

  const deleteImg = async (idx: number) => {
    let newImages: any[] = [...(broker?.photos || [])];
    newImages.splice(idx, 1);
    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: newImages,
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        photos: res?.photos,
      });
    });
  };

  return (
    <>
      <div className="flex flex-col w-full h-full relative overflow-auto">
        <div className="flex flex-row justify-between items-center pt-8 mx-10 mb-14">
          <h3 className="text-xl mb-2">Profile image</h3>
          <SolButton
            className="whitespace-nowrap"
            onSol-click={() => navigate(`/profile/${brokerId}/photo/gallery`)}
            disabled={!broker?.ai_images?.length}
          >
            <SolIcon icon="gallery_thumbnail" /> Photo gallery
          </SolButton>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 mx-10 relative gap-12">
          <div className="col-span-1 bg-jll-surface-base-secondary-subdued rounded-xl flex justify-center items-center flex-col p-6 h-[330px]">
            <h3 className="text-xl mb-2">Aragon, AI headshot generator!</h3>
            <p className="text-jll-text-base-subdued text-sm text-center mb-6">
              Get professional headshots using Aragon, AI headshot generator.
              You will be redirected to Aragon external site to complete the
              process.
            </p>
            <SolButton variant="secondary" onSol-click={handleAragon}>
              Start <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
          <div className="col-span-1 bg-jll-surface-base-secondary-subdued rounded-xl flex justify-center items-center flex-col p-6 h-[330px]">
            <h3 className="text-xl mb-2">Upload your photos!</h3>
            <p className="text-jll-text-base-subdued text-sm text-center mb-6">
              Alternatively you can choose to upload your photo, following the
              headshots - best practice guidelines!
            </p>
            <SolButton
              variant="secondary"
              onSol-click={() => navigate(`/profile/${brokerId}/photo/manual`)}
            >
              Start <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePhoto;
