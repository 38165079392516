import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const ReactSlider = (props: any) => {
  return (
    <>
      <Slider
        {...props}
        railStyle={{ background: "#F6F9FA" }}
        trackStyle={{ background: "#2FA0C7" }}
        handleStyle={{
          width: 16,
          height: 16,
          background: "#2FA0C7",
          border: "solid 2px #FFF",
          opacity: 1,
          marginTop: -6
        }}
      />
    </>
  );
};

export default ReactSlider;
