import { SolIcon } from "@solstice/sol-react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TutorialViewModal } from "ui-molecules";
import cn from "classnames";

export interface TutorialStepItemProps {
  index: number;
  title: string;
  description: string;
  imageUrl: string;
  setIsOpen: any;
  setSelectedIdx: any;
}

interface TutorialStepProps {
  steps: {
    title: string;
    description: string;
    imageUrl: string;
    index: number;
  }[];
  className?: string;
}

const TutorialStepItem: React.FC<TutorialStepItemProps> = ({
  index,
  title,
  description,
  imageUrl,
  setIsOpen,
  setSelectedIdx,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <div className="col-span-1">
        <div className="mb-6 rounded-lg w-full aspect-[282/214]">
          {imageUrl ? (
            <div className="w-full h-full relative">
              <LazyLoadImage
                src={imageUrl}
                className="w-full h-full rounded-lg cursor-pointer hover:shadow-gallery"
                alt="Will be coming from Brand"
                onClick={() => {
                  setSelectedIdx(index - 1);
                  setIsOpen(true);
                }}
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              />
              {isHover && (
                <div className="w-8 h-8 min-w-8 min-h-8 absolute top-1.5 right-1.5 bg-white rounded-full p-1.5 flex justify-center items-center shadow-gallery">
                  <SolIcon icon="open_in_full" size="20" />
                </div>
              )}
            </div>
          ) : (
            <div className="w-full h-full bg-jll-text-base-subdued-4 flex justify-center items-center p-4 text-jll-text-base-subdued-5">
              Will be coming from Brand!
            </div>
          )}
        </div>

        <div className="flex flex-row space-x-2">
          {!!index && (
            <div className="min-w-[28px] w-[28px] min-h-[28px] h-[28px] flex justify-center items-center bg-jll-surface-base-secondary rounded-full">
              {index}
            </div>
          )}
          <div>
            <p className="mb-3">{title}</p>
            <p className="text-jll-text-base-subdued text-sm">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const TutorialStep: React.FC<TutorialStepProps> = ({ steps, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(0);

  return (
    <>
      <div
        className={cn("grid xl:grid-cols-4 md:grid-cols-2 gap-8", className)}
      >
        {steps?.map(({ index, title, description, imageUrl }, idx) => (
          <TutorialStepItem
            key={idx}
            index={index}
            title={title}
            description={description}
            imageUrl={imageUrl}
            setIsOpen={setIsOpen}
            setSelectedIdx={setSelectedIdx}
          />
        ))}
      </div>
      <TutorialViewModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        steps={steps}
        selectedIdx={selectedIdx}
        setSelectedIdx={setSelectedIdx}
      />
    </>
  );
};

export default TutorialStep;
