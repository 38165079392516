import { LazyLoadImage } from "react-lazy-load-image-component";
import HomepageBg from "assets/images/homepage_bg.jpg";
import JLLLogo from "assets/images/jll_32_logo.svg";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { useOktaAuth } from "@okta/okta-react";
import pkceChallenge from "pkce-challenge";
import Cookies from "js-cookie";
import oktaAuth from "oktaConfig";

const OriginalHome = () => {
  const generateUrlSafeToken = (length: number) => {
    const array: any = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return btoa(String.fromCharCode(...array))
      .replace(/\+/g, "-") // Replace '+' with '-'
      .replace(/\//g, "_") // Replace '/' with '_'
      .replace(/=+$/, ""); // Remove '=' padding
  };

  const handleLogin = async () => {
    const { code_verifier, code_challenge } = await pkceChallenge();
    const state = generateUrlSafeToken(32);
    const nonce = generateUrlSafeToken(32);

    await Cookies.set("state", state);
    await Cookies.set("nonce", nonce);
    await Cookies.set("code_verifier", code_verifier);

    try {
      await oktaAuth.signInWithRedirect({
        codeChallenge: code_challenge,
        codeVerifier: code_verifier,
        state,
        nonce,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full h-full flex flex-row">
      <div className="flex flex-col justify-center h-full bg-jll-surface-base-default px-[72px] text-white">
        <div className="w-full mb-12">
          <LazyLoadImage src={JLLLogo} />
        </div>
        <h2 className="text-3xl whitespace-nowrap mb-2">Profile management</h2>
        <div className="text-xl whitespace-nowrap mb-2">
          <p>JLL's home to manage broker profile data and</p>
          <p>related metadata</p>
        </div>
        <p className="text-sm mb-12">
          Manage your data and sync your changes to external systems
        </p>
        <SolButton size="large" onSol-click={handleLogin}>
          Login
          <SolIcon icon="login" />
        </SolButton>
      </div>
      <div className="w-full h-full">
        <LazyLoadImage
          src={HomepageBg}
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default OriginalHome;
