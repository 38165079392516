import { LayoutBase } from "ui-organisms";
import cn from "classnames";
import { GlobalLeftBar, ListLeftSide, TopBar } from "ui-molecules";
import { useState } from "react";
import { BrokerProps } from "types";

interface ILayoutDetail {
  children: any;
  className?: string;
  broker?: BrokerProps | null;
}

const LayoutDetail: React.FC<ILayoutDetail> = ({ children, className, broker }) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const [isOpen, setIsOpen] = useState(false);
  const isFullPermission =
    !!session?.permissions?.groups?.length ||
    !!session?.is_superuser ||
    !!session?.is_staff;

  return (
    <LayoutBase>
      <TopBar broker={broker}/>
      <div className="absolute top-[80px] left-0 right-0 bottom-0 ">
        <div className="w-full h-full relative flex flex-row">
          {isFullPermission && (
            <GlobalLeftBar isOpen={isOpen} setIsOpen={setIsOpen} />
          )}
          <div
            className={cn(
              "absolute right-0 top-0 bottom-0 transition-width duration-300 flex flex-row",
              {
                "left-[250px]": isFullPermission && isOpen,
                "left-[104px]": isFullPermission && !isOpen,
                "left-0": !isFullPermission,
              }
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </LayoutBase>
  );
};

export default LayoutDetail;
