import { LazyLoadImage } from "react-lazy-load-image-component";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { AIImageProps, BrokerProps, PhotoHistoryProps } from "types";
import React, { useContext, useState } from "react";
import { getFormatedDateTime } from "utils";
import { useApiCall } from "hooks";
import { patchBrokerAPI } from "services";
import { Table, ViewPhotoModal } from "ui-molecules";
import { GlobalContext } from "context";
import { SET_LOADING_MODAL } from "constant";
import { Toast } from "ui-atoms";

interface AIPhotoGalleryProps {
  broker: BrokerProps | null;
  setBroker: any;
}

interface GalleryItemProps {
  aiImage: AIImageProps;
  broker: BrokerProps | null;
  setBroker: any;
}

const GalleryItem: React.FC<GalleryItemProps> = ({
  aiImage,
  broker,
  setBroker,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [patchBroker] = useApiCall(patchBrokerAPI);
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isHover, setIsHover] = useState(false);

  const setPrimaryImg = async (url: string) => {
    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: [url],
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        photos: res?.photos,
        photo_history: res?.photo_history,
      });
      Toast.success("Profile photo successfully updated!");
    });
  };

  const handleDownload = async (imageUrl: string) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Downloading a photo",
        },
      });
      const response = await fetch(imageUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      // Ensure the response is okay
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }

      // Convert response into a Blob
      const blob = await response.blob();

      // Create a temporary URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = imageUrl.split("/").pop() || "image.png";
      link.click();
      link.remove();
    } catch (err) {
    } finally {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    }
  };

  const deleteImg = async (id: number) => {
    let newImages: any[] = [...(broker?.ai_images || [])];
    const index = newImages?.findIndex((item: AIImageProps) => item.id === id);
    if (index < 0) return;
    newImages.splice(index, 1);
    patchBroker({
      pk: broker?.pk || broker?.id,
      ai_images: newImages,
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        ai_images: res?.photos,
      });
    });
  };

  return (
    <>
      <div className="col-span-1 flex flex-col relative">
        <LazyLoadImage
          src={aiImage.image}
          className="rounded-lg mb-6 aspect-[886/1040] object-cover hover:shadow-gallery cursor-pointer"
          onClick={() => {
            setImageUrl(aiImage.image);
            setIsOpen(true);
          }}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        />
        <div>
          <div className="flex flex-row items-center justify-between mb-6">
            <SolButton
              size="small"
              variant="secondary"
              className="whitespace-nowrap"
              onSol-click={() => setPrimaryImg(aiImage?.image)}
              disabled={broker?.photos?.[0] === aiImage?.image}
            >
              Use as primary
            </SolButton>
            <SolButton
              size="small"
              variant="outlined"
              onSol-click={() => handleDownload(aiImage.image)}
            >
              <SolIcon icon="download" />
            </SolButton>
          </div>
        </div>
        {broker?.photos?.[0] === aiImage?.image && (
          <div className="w-[26px] h-[26px] border-2 border-white rounded-full absolute left-2.5 top-3 bg-jll-icon-rag-success-2 flex justify-center items-center">
            <SolIcon icon="check" className="text-white font-bold" size="20" />
          </div>
        )}
        {isHover && (
          <div className="w-8 h-8 min-w-8 min-h-8 absolute top-1.5 right-1.5 bg-white rounded-full p-1.5 flex justify-center items-center shadow-gallery">
            <SolIcon icon="open_in_full" size="20" />
          </div>
        )}
      </div>
      <ViewPhotoModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        imageUrl={imageUrl}
        setPrimaryImg={setPrimaryImg}
      />
    </>
  );
};

const AIPhotoGallery: React.FC<AIPhotoGalleryProps> = ({
  broker,
  setBroker,
}) => {
  const [patchBroker] = useApiCall(patchBrokerAPI);

  const setPrimaryImg = async (url: string) => {
    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: [url],
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        photos: res?.photos,
        photo_history: res?.photo_history,
      });
      Toast.success("Profile photo successfully updated!");
    });
  };

  const deleteHistory = async (id: number) => {
    let newHistory: any[] = [...(broker?.photo_history || [])];
    const index = newHistory?.findIndex((item: AIImageProps) => item.id === id);
    if (index < 0) return;
    newHistory.splice(index, 1);
    patchBroker({
      pk: broker?.pk || broker?.id,
      photo_history: newHistory,
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        photo_history: res?.photo_history,
      });
    });
  };

  return (
    <div className="flex flex-col w-full h-full relative overflow-auto px-10">
      <div className="flex flex-col pt-8 mb-[56px]">
        <h3 className="text-xl mb-2">Photo gallery</h3>
        <p className="text-sm text-jll-text-base-subdued">
          Please select your favourite headshot to be used as your profile
          picture.
        </p>
      </div>
      {!!broker?.ai_images?.length && (
        <div className="mb-[56px]">
          <p className="text-xl mb-2">Aragon, AI headshot generated images!</p>
          <p className="text-sm text-jll-text-base-subdued mb-[56px]">
            Double click on the image to enlarge.
          </p>
          <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10">
            {broker?.ai_images?.map((item: AIImageProps, idx) => (
              <GalleryItem
                key={idx}
                aiImage={item}
                setBroker={setBroker}
                broker={broker}
              />
            ))}
          </div>
        </div>
      )}

      {!!broker?.photo_history?.length && (
        <div className="mb-[56px]">
          <div className="flex flex-col pt-8 mb-12">
            <h3 className="text-xl mb-2">Profile photo -History</h3>
            <p className="text-sm text-jll-text-base-subdued">
              Curabitur a ipsum eget dolor malesuada tristique
            </p>
          </div>

          <Table>
            <Table.Tbody>
              {[...broker?.photo_history]
                ?.sort((a, b) => {
                  const dateA = new Date(a.update_timestamp);
                  const dateB = new Date(b.update_timestamp);
                  return dateB.getTime() - dateA.getTime();
                })
                ?.map((history: PhotoHistoryProps, idx) => (
                  <Table.Tr key={idx}>
                    <Table.Td>
                      <LazyLoadImage
                        src={history.photo}
                        className="w-[68px] min-w-[68px] h-[68px] min-h-[68px] rounded-md object-cover"
                      />
                    </Table.Td>
                    <Table.Td>
                      <p>Modified by</p>
                      <p className="text-jll-text-base-subdued">
                        {[
                          history?.updated_user_first_name,
                          history?.updated_user_last_name,
                        ]?.join(" ") || history?.updated_user_email}
                      </p>
                    </Table.Td>
                    <Table.Td>
                      <p>Modified on</p>
                      <p className="text-jll-text-base-subdued">
                        {getFormatedDateTime(history?.update_timestamp)}
                      </p>
                    </Table.Td>
                    <Table.Td className="text-right space-x-4">
                      <SolButton
                        variant="outlined"
                        onSol-click={() => setPrimaryImg(history.photo)}
                      >
                        <SolIcon icon="check" />
                        Use as Primary
                      </SolButton>
                      <SolButton
                        variant="outlined"
                        onSol-click={() => deleteHistory(history.id)}
                      >
                        <SolIcon icon="delete" />
                      </SolButton>
                    </Table.Td>
                  </Table.Tr>
                ))}
            </Table.Tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default AIPhotoGallery;
