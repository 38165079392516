import { SolButton, SolDropdown, SolIcon } from "@solstice/sol-react";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getIndustryAPI, getServiceAPI, postProfileAPI } from "services";
import { Modal } from "ui-molecules";
import { getOptions } from "utils";
import * as Yup from "yup";

interface ICountryModal {
  isOpen: boolean;
  setIsOpen: any;
  profiles: any;
  setProfiles: any;
}

const INITIAL_VALUES = {
  country: [],
  language: [],
  region: [],
};

const validationSchema = Yup.object().shape({
  country: Yup.array().required("This field is required"),
  language: Yup.array().required("This field is required"),
});

const CountryModal: React.FC<ICountryModal> = ({
  isOpen,
  setIsOpen,
  profiles,
  setProfiles,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const { brokerId } = useParams();
  const [postProfile] = useApiCall(postProfileAPI);

  useEffect(() => {
    if (!isOpen) return;
  }, [isOpen]);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async () => {
      postProfile({
        broker: brokerId,
        country: values?.country?.[0],
        language: values?.language?.[0],
      }).then((res: any) => {
        if (!res) return;
        setProfiles([...profiles, res]);
        setIsOpen(false);
      });
    },
  });

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">Add a country</h2>
            <p>
              Select your country, region, and language to create a profile in
              your local language to make your profile visible on other jll.com
              sites (if applicable)
            </p>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="mb-6">
            <SolDropdown
              label="Select Country"
              required
              size="small"
              options={getOptions("country", meta)}
              value={values.country}
              onUpdate-value={(e) => {
                setFieldValue("country", [e?.detail]);
              }}
              onBlur={handleBlur}
              errorLabel={
                touched.country ? (errors.country as string) : undefined
              }
            />
          </div>

          <div className="mb-6">
            <SolDropdown
              label="Select Region"
              required
              size="small"
              options={
                meta?.["region"]
                  ? Object?.keys(meta?.["region"])?.map(
                      (key: string | number) => ({
                        label: meta?.["region"]?.[key],
                        value: key,
                      })
                    )
                  : []
              }
              value={values.region}
              onUpdate-value={(e) => {
                setFieldValue("region", [e?.detail]);
              }}
              onBlur={handleBlur}
              errorLabel={
                touched.region ? (errors.region as string) : undefined
              }
            />
          </div>

          <div className="mb-8">
            <SolDropdown
              label="Select Language"
              required
              size="small"
              options={getOptions("language", meta)}
              value={values.language}
              onUpdate-value={(e) => {
                setFieldValue("language", [e?.detail]);
              }}
              onBlur={handleBlur}
              errorLabel={
                touched.language ? (errors.language as string) : undefined
              }
            />
          </div>
          <div className="flex flex-row space-x-3 w-full justify-end mt-6">
            <SolButton
              variant="secondary"
              className="!w-[96px] sol-w-full"
              onSol-click={() => setIsOpen(false)}
            >
              Cancel
            </SolButton>
            <SolButton
              className="!w-[96px] sol-w-full"
              disabled={!isValid || !dirty}
              itemType="submit"
            >
              Save
            </SolButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CountryModal;
