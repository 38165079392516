import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { useSearchParams } from "react-router-dom";
import { patchProfileAPI } from "services";
import { BrokerProps, ProfileProps } from "types";
import { convertFromHTML, convertToHTML } from "draft-convert";
import * as Yup from "yup";
import { SolButton } from "@solstice/sol-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import cn from "classnames";
import { ensureH2, parseOriginalJSON } from "utils";
import { GlobalContext } from "context";
import { EDITOR_TOOLBAR_OPTIONS, SET_LOADING_MODAL } from "constant";

interface MainBioProps {
  profile: ProfileProps | null;
  setProfile?: any;
  handleGPT?: any;
  setActiveTab?: any;
  setMode: any;
  broker?: BrokerProps | null;
}

const INITIAL_VALUES = {
  bio: EditorState.createEmpty(),
};

const validationSchema = Yup.object().shape({
  bio: Yup.string().required("This field is required"),
});

const MainBio = forwardRef(
  (
    { profile, setProfile, setActiveTab, setMode, broker }: MainBioProps,
    ref
  ) => {
    const { dispatch } = useContext(GlobalContext);
    const [searchParams] = useSearchParams();
    const [patchProfile] = useApiCall(patchProfileAPI);

    useEffect(() => {
      if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
        return;
      let formValues: any = {};
      Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
        if (key === "bio") {
          if (!profile?.bio) {
            formValues = {
              ...formValues,
              [key]: EditorState.createEmpty(),
            };
            return;
          }
          const ensuredBio = !!broker?.leadership
            ? ensureH2(profile?.bio, ["current responsibilities"])
            : ensureH2(profile?.bio, [
                "current responsibilities",
                "experience",
              ]);
          const rawContentState = parseOriginalJSON(ensuredBio);
          // const rawContentState = parseOriginalJSON(profile?.bio);
          const contentState: any = convertFromHTML(rawContentState);
          formValues = {
            ...formValues,
            [key]: EditorState.createWithContent(contentState),
          };
          return;
        }
        formValues = {
          ...formValues,
          [key]: (profile as any)?.[key] || "",
        };
      });
      setValues({
        ...formValues,
      });
    }, [profile]);

    useImperativeHandle(ref, () => ({
      saveBioData: () => {
        handleUpdate();
      },
    }));

    const { handleBlur, setFieldValue, values, errors, touched, setValues } =
      useFormik({
        initialValues: INITIAL_VALUES,
        validationSchema: validationSchema,
        onSubmit: async () => {},
      });

    const handleUpdate = () => {
      try {
        const contentState = values?.bio?.getCurrentContent();
        const rawContentState = !!broker?.leadership
          ? ensureH2(convertToHTML(contentState) as any, [
              "current responsibilities",
            ])
          : ensureH2(convertToHTML(contentState) as any, [
              "current responsibilities",
              "experience",
            ]);
        // const rawContentState = convertToHTML(contentState);
        const contentString = JSON.stringify(rawContentState);
        const value = contentString;
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            title: "Saving bio content",
          },
        });
        patchProfile({
          pk: profile?.id,
          bio: value,
        })
          .then((res: any) => {
            if (!res) return;
            setProfile({
              ...profile,
              bio: value,
            });
          })
          .finally(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                title: "",
              },
            });
            setActiveTab(0);
            setMode(0);
          });
      } catch (err) {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: "",
          },
        });
      }
    };

    return (
      <div className="mb-12">
        <div className="relative border border-jll-light-stroke-default rounded">
          <Editor
            editorState={values?.bio as any}
            toolbarClassName="hidden"
            wrapperClassName="border-none"
            editorClassName={cn("px-5", {})}
            onEditorStateChange={(e) => {
              setFieldValue("bio", e);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            toolbar={EDITOR_TOOLBAR_OPTIONS}
          />
        </div>

        <div className="flex flex-row items-center justify-between my-10">
          <SolButton
            variant="outlined"
            onSol-click={() => {
              setMode(0);
              setActiveTab(0);
            }}
          >
            Cancel
          </SolButton>
        </div>
      </div>
    );
  }
);

export default MainBio;
