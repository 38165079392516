import { SolButton } from "@solstice/sol-react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BrokerProps, ProfileProps } from "types";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { ensureH2, parseOriginalJSON } from "utils";
import { useFormik } from "formik";
import cn from "classnames";
import BioTemplateModal from "./BioTemplateModal";
import { useApiCall } from "hooks";
import { getProfileExampleAPI, patchProfileAPI } from "services";
import { GlobalContext } from "context";
import { EDITOR_TOOLBAR_OPTIONS, SET_LOADING_MODAL } from "constant";

interface ManualBioProps {
  profile: ProfileProps | null;
  setProfile: any;
  setMode: any;
  broker?: BrokerProps | null;
}

const INITIAL_VALUES = {
  bio: EditorState.createEmpty(),
};

const validationSchema = Yup.object().shape({
  bio: Yup.string().required("This field is required"),
});

const ManualBio: React.FC<ManualBioProps> = ({
  profile,
  setProfile,
  setMode,
  broker,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [getProfileExample] = useApiCall(getProfileExampleAPI);
  const [examples, setExamples] = useState([]);
  const [selectedIdx, setSeletecIdx] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getProfileExample().then((res: any) => {
      if (!res) {
        setExamples([]);
        return;
      }
      setExamples(res);
    });
  }, []);

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "bio") {
        if (!profile?.bio) {
          formValues = {
            ...formValues,
            [key]: EditorState.createEmpty(),
          };
          return;
        }
        const ensuredBio = !!broker?.leadership
          ? ensureH2(profile?.bio, ["current responsibilities"])
          : ensureH2(profile?.bio, ["current responsibilities", "experience"]);
        const rawContentState = parseOriginalJSON(ensuredBio);
        const contentState: any = convertFromHTML(rawContentState);
        formValues = {
          ...formValues,
          [key]: EditorState.createWithContent(contentState),
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (profile as any)?.[key] || "",
      };
    });
    setValues({
      ...formValues,
    });
  }, [profile]);

  const { handleBlur, setFieldValue, values, errors, touched, setValues } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  const handleUpdate = (key: string, value: any) => {
    try {
      if (key === "bio") {
        const contentState = values?.bio?.getCurrentContent();
        const rawContentState = !!broker?.leadership
          ? ensureH2(convertToHTML(contentState) as any, [
              "current responsibilities",
            ])
          : ensureH2(convertToHTML(contentState) as any, [
              "current responsibilities",
              "experience",
            ]);
        const contentString = JSON.stringify(rawContentState);
        value = contentString;
      }
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Saving bio content",
        },
      });
      patchProfile({
        pk: profile?.id,
        [key]: value,
      })
        .then((res: any) => {
          if (!res) return;
          setProfile({
            ...profile,
            [key]: value,
          });
        })
        .finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
          setMode(0);
        });
    } catch (err) {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          title: "",
        },
      });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between mb-6">
          <h2 className="text-xl mb-1">Bio - Manual update</h2>
          <div className="flex flex-row items-center space-x-4">
            <SolButton variant="tertiary" onSol-click={() => setMode(0)}>
              Cancel
            </SolButton>
            <SolButton
              variant="secondary"
              onSol-click={() => {
                handleUpdate("bio", values?.bio);
              }}
            >
              Save
            </SolButton>
          </div>
        </div>

        <div className="absolute top-[92px] left-0 right-0 bottom-0 px-10 overflow-y-auto">
          <div className="border border-jll-light-stroke-default rounded mb-6">
            <Editor
              editorState={values?.bio as any}
              toolbarClassName="hidden"
              wrapperClassName="border-none"
              editorClassName={cn("px-5", {})}
              onEditorStateChange={(e) => {
                setFieldValue("bio", e);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              toolbar={EDITOR_TOOLBAR_OPTIONS}
            />
          </div>
          <div className="mb-12">
            <div className="mb-6">
              <h2 className="text-xl mb-1">Some of the best bio examples!</h2>
              <p className="text-sm text-jll-text-base-subdued">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                efficitur elementum erat, vel interdum ipsum mollis id. Maecenas
                sollicitudin nunc in accumsan posuere.
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4">
              {examples?.map((item, idx) => (
                <SolButton
                  key={idx}
                  variant="outlined"
                  onSol-click={() => {
                    setSeletecIdx(idx);
                    setIsOpen(true);
                  }}
                >
                  Example {idx + 1}
                </SolButton>
              ))}
            </div>
          </div>
        </div>
      </div>
      <BioTemplateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        examples={examples}
        selectedIdx={selectedIdx}
        profile={profile}
        setProfile={setProfile}
        setMode={setMode}
        broker={broker}
      />
    </>
  );
};

export default ManualBio;
