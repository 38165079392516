import { SolButton, SolIcon } from "@solstice/sol-react";
import { useRef, useState } from "react";
import { BrokerProps } from "types";
import EditPhotoModal from "./EditPhotoModal";
import ManualImg1 from "assets/images/manual_1.jpeg";
import ManualImg2 from "assets/images/manual_2.jpeg";
import ManualImg3 from "assets/images/manual_3.jpeg";
import ManualImg4 from "assets/images/manual_4.jpeg";
import { TutorialStep } from "ui-molecules";

interface ManualPhotoProps {
  broker: BrokerProps | null;
  setBroker: any;
}

const STEPS = [
  {
    index: 1,
    title: "Familiarize and prepare",
    description: "Review our headshot guidelines and our requirements of you.",
    imageUrl: ManualImg1,
  },
  {
    index: 2,
    title: "Get your photo taken",
    description: "Request a new photo by reaching out to your support team.",
    imageUrl: ManualImg2,
  },
  {
    index: 3,
    title: "Format and optimize",
    description:
      "Adjust your chosen image to meet our website specifications (see guidelines below).",
    imageUrl: ManualImg3,
  },
  {
    index: 4,
    title: "Upload your images",
    description:
      "Your image goes live, and if there are any issues, JBS will be in touch.",
    imageUrl: ManualImg4,
  },
];

const ManualPhoto: React.FC<ManualPhotoProps> = ({ broker, setBroker }) => {
  const fileInputRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");

  const handleFileChange = async (event: any) => {
    const file = event?.target?.files[0];
    if (file) {
      try {
        setIsOpen(true);
        setImage("");
        const reader = new FileReader();
        reader.onload = () => setImage(reader.result as string);
        reader.readAsDataURL(file);
      } catch (err) {}
    }
  };
  return (
    <>
      <div className="flex flex-col w-full h-full relative overflow-auto px-10">
        <div className="flex flex-col pt-8 mb-12">
          <h3 className="text-xl mb-2">
            Photographic headshots, upload your photos!
          </h3>
          <p className="text-sm text-jll-text-base-subdued">
            Ready to upload your new professional headshot? Here’s what you can
            expect
          </p>
        </div>
        <div className="mb-[88px]">
          <TutorialStep steps={STEPS} className="mb-12" />
          <SolButton
            onSol-click={() => {
              fileInputRef.current.click();
            }}
          >
            Click to proceed <SolIcon icon="upload" />
          </SolButton>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*"
            onChange={handleFileChange} // Handle file selection
            onClick={(event: any) => {
              fileInputRef.current.value = "";
            }}
          />
        </div>

        <div className="mb-14">
          <h4 className="text-xl mb-2">Need more information?</h4>
          <p className="text-sm text-jll-text-base-subdued mb-6">
            Click the{" "}
            <span className="text-jll-text-base-default">
              ‘How to guidelines’
            </span>{" "}
            button below for step-by-step instructions.
          </p>
          <SolButton
            variant="secondary"
            onSol-click={() =>
              window.open(
                "https://jll2-my.sharepoint.com/:p:/g/personal/briggs_thompson_am_jll_com/ESiPPetkO9VGnkoeKZegOpMBXPUvledsd4UkYXy2aDz7Mg?e=gzVZ1d",
                "_blank"
              )
            }
          >
            How to guidelines
            <SolIcon icon="arrow_right_alt" />
          </SolButton>
        </div>
      </div>
      <EditPhotoModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        image={image}
        broker={broker}
        setBroker={setBroker}
      />
    </>
  );
};

export default ManualPhoto;
