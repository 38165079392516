import { SolButton, SolIcon } from "@solstice/sol-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Modal } from "ui-molecules";
import { useEffect, useState } from "react";
import cn from "classnames";

interface TutorialViewModalProps {
  isOpen: boolean;
  setIsOpen: any;
  steps: { title: string; description: string; imageUrl: string }[];
  selectedIdx: number;
  setSelectedIdx: any;
}
const TutorialViewModal: React.FC<TutorialViewModalProps> = ({
  isOpen,
  setIsOpen,
  steps,
  selectedIdx,
  setSelectedIdx,
}) => {
  const handlePrev = () => {
    setSelectedIdx((prevIndex: number) =>
      prevIndex === 0 ? steps.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setSelectedIdx((prevIndex: number) =>
      prevIndex === steps.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size="medium"
      isPrev={selectedIdx !== 0}
      isNext={selectedIdx !== steps?.length - 1}
      handlePrev={handlePrev}
      handleNext={handleNext}
    >
      <Modal.Body>
        <div className="absolute top-6 right-6 z-40">
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
        <div className="flex overflow-hidden relative">
          <div
            className={cn("flex transition-transform ", {
              "duration-500 ease-in-out": isOpen,
            })}
            style={{ transform: `translateX(-${selectedIdx * 100}%)` }}
          >
            {steps?.map((item, idx) => (
              <div key={idx} className="w-full flex-shrink-0">
                <div className="flex flex-col mb-6">
                  <h2 className="text-xl">{item?.title}</h2>
                  <p>{item?.description}</p>
                </div>

                <LazyLoadImage
                  src={item?.imageUrl}
                  className="rounded-lg w-full aspect-[282/214]"
                />
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TutorialViewModal;
