/**
 * Capitalize string
 * @param value Ex: ryong
 * @returns Ex: Ryong
 */
export const capitalize = (s: string | undefined) => {
  if (!s) return "";
  return s[0].toUpperCase() + s.slice(1);
};

/**
 * Capitalize first letters of name
 * @param value Ex: ryong ma
 * @returns Ex: RM
 */
export const capitalizeName = (name: string = "") => {
  const arr = name?.split(" ");
  return `${arr[0]?.[0]?.toUpperCase() || ""}${
    arr[1]?.[0]?.toUpperCase() || ""
  }`;
};

/**
 * Capitalize all words
 * @param value Ex: date_available, _
 * @returns Ex: Date Available
 */
export const capitalizeWords = (
  str: string = "",
  splitLetter: string = " "
) => {
  try {
    const arr = str?.split(splitLetter);
    let newStr = "";
    arr?.forEach((s, idx) => {
      newStr += `${capitalize(s)}`;
      if (idx < arr?.length - 1) newStr += " ";
    });
    return newStr;
  } catch (err) {
    return "";
  }
};

/**
 * Capitalize all words
 * @param value Ex: Flex r&d, &
 * @returns Ex: Flex r&D
 */
export const capitalizeSymbolWords = (
  str: string = "",
  splitLetter: string = "&"
) => {
  try {
    const arr = str?.split(splitLetter);
    let newStr = "";
    if (arr?.length <= 1) return str;
    arr?.forEach((s, idx) => {
      newStr += `${capitalize(s)}`;
      if (idx < arr.length - 1) newStr += splitLetter;
    });
    return newStr;
  } catch (err) {
    return "";
  }
};

/**
 * return the first name from full name
 * @param value Ex: ryong ma
 * @returns Ex: Ryong
 */
export const getFirstnameFromFullname = (name: string = "") => {
  const arr = name?.split(" ");
  return capitalize(arr?.[0]) || "";
};

/**
 * Join 2 strings
 * @param value Ex: building, address
 * @returns Ex: "building, address"
 */
export const joinTwoStr = (
  str1: string = "",
  str2: string = "",
  joinStr: string = ", "
) => {
  const label = [];
  if (str1) {
    label.push(str1);
  }
  if (str2) {
    label.push(str2);
  }
  return label.join(joinStr);
};

/**
 * Returns full address
 * @param value Ex: address, city, state, code
 * @returns Ex: "address city, state code"
 */
export const getFullAddress = (
  address: string = "",
  city: string = "",
  state: string = "",
  code: string = ""
) => {
  const str1 = joinTwoStr(address, city, " ") || "";
  const str2 = joinTwoStr(state, code, " ") || "";
  return joinTwoStr(str1, str2);
};

/**
 * Returns Past Tense
 * @param value Ex: add or update
 * @returns Ex: "added" or "updated"
 */
export const getPastTense = (value: string) => {
  switch (value?.toLowerCase()) {
    case "add":
      return "added";
    default:
      return `${value}d`;
  }
};

export const getOptions = (key: string, meta?: any) => {
  if (!meta?.[key]) return [];
  return meta?.[key].map((item: any) => ({
    value: item?.id,
    label: item?.description || item?.name,
  }));
};

export const parseJSON = (str: string) => {
  try {
    return JSON.parse(str?.replaceAll(/\\054/g, ",")?.replaceAll("'", '"'));
  } catch (err) {
    return str?.replace(/\\054/g, ",");
  }
};

export const parseOriginalJSON = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return str;
  }
};

export const ensureH2 = (input: string, targets: string[]) => {
  input = input?.replaceAll("<h2>", "<p>")?.replaceAll("</h2>", "</p>");
  input = input.replace(/<p>\s*(.*?)\s*<\/p>/g, (match, content) => {
    // Trim extra spaces and replace double spaces within content
    let cleanedContent = content.trim().replace(/\s{2,}/g, " ");
    return `<p>${cleanedContent}</p>`;
  });
  targets?.forEach((target) => {
    // Regular expression to check if 'Current responsibilities' is in h2 tag (case-insensitive)
    // const regex = /<h2>(.*?)<\/h2>/i;
    // Check if 'Current responsibilities' is included in the string, case-insensitive
    const targetStringFound = input
      .toLowerCase()
      .includes(`${target.toLowerCase()}`);
    const regex = /<p>(.*?)<\/p>/i;
    // If found and not wrapped in <h2> tag, add <h2> around the string
    if (targetStringFound && regex.test(input)) {
      // Case-sensitive replacement with <h2> tag around the original string
      input = input.replaceAll(
        new RegExp(`(<p>${target}</p>)`, "gi"), // match both uppercase and lowercase
        `<h2>${capitalizeWords(target, " ")}</h2>` // wrap the matched string in <h2>
      );
    }
  });

  return input;
};
